import { useLocation } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { useUser } from '~/providers';
import { analytics } from '~/services/segment.client';

// This hook takes care of
// - identifying the user in segment when the user logs in
// - resetting the segment user when the user logs out
// - resetting all segment users to this day so all users start with a clean slate
// - tracking page views

const OFFICE_IP = '152.115.124.6';

type Props = {
  ip: string | null;
  shouldLogout?: boolean;
};

export const useSegmentInit = ({ shouldLogout = false, ip }: Props) => {
  const currentLocation = useLocation();
  const { user, account } = useUser();
  const [shouldTrack, setShouldTrack] = useState<boolean | null>(null);

  useEffect(() => {
    if (ip === OFFICE_IP) {
      console.log('Office IP detected; skipping analytics events.');
      setShouldTrack(false);
    } else {
      setShouldTrack(true);
    }
  }, [ip]);

  // This effect takes care of identifying the user in segment when the user logs in
  useEffect(() => {
    if (shouldTrack !== true) return;
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();

      if (!segmentUserId && user && account) {
        analytics.identify(user.id.toString().padStart(8, '0'), {
          companyId: account.id.toString().padStart(8, '0'),
          freemium: account.features.free_access?.amount === 1,
          internal: user.email.includes('@raffle.ai'),
        });
      }
    });
  }, [user, account, shouldTrack]);

  // This effect takes care of resetting the segment user when the user logs out
  useEffect(() => {
    if (shouldTrack !== true) return;
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();

      if (segmentUserId && shouldLogout) {
        segmentUser.reset();
      }
    });
  }, [shouldLogout, shouldTrack]);

  // This effect takes care of resdetting all segment users to this day so all users start with a clean slate
  useEffect(() => {
    if (shouldTrack !== true) return;
    analytics.user().then((segmentUser) => {
      const segmentUserId = segmentUser.id();
      if (
        (segmentUserId && segmentUserId.length < 8) ||
        segmentUserId?.includes('-')
      ) {
        analytics.reset();
      }
    });
  }, [shouldTrack]);

  // This effect takes care of tracking page views
  useEffect(() => {
    if (shouldTrack !== true) return;
    analytics.user().then(() => {
      analytics.page();
    });
  }, [currentLocation.pathname, shouldTrack]);
};
